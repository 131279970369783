import React, { Component } from "react";
import ReactDOM from "react-dom";

export default class ScopeOfWorkItemsApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scopeCollections: [],
      isLoading: true,
      quoteVersion: this.props.quote_version
        ? JSON.parse(this.props.quote_version)
        : null,
      quote: this.props.quote ? JSON.parse(this.props.quote) : null,
      quoteVersionID:
        window.location.href.split("/")[
          window.location.href.split("/").length - 1
        ],
      domain: window.location.hostname,
    };
  }

  toggleBulkButton() {
    let cnt = 0;
    $(".item-select-box").each(function () {
      if ($(this).is(":checked")) {
        cnt++;
      }
    });

    if (cnt == 0) {
      $(".apply-button").attr("disabled", "disabled");
      $(".apply-button").text("Please select an item");
    } else {
      $(".apply-button").removeAttr("disabled");
      $(".apply-button").text("Apply to selected items");
    }
  }

  toggleGroupBoxes(data, event) {
    if (event.target.checked) {
      $(`.group-${data.collection_id}`).prop("checked", true);
    } else {
      $(`.group-${data.collection_id}`).prop("checked", false);
    }

    this.toggleBulkButton();
  }

  setPhoto(data, event) {
    $("#scopePhoto .modal-body").html(
      `<img src="${data.photo}" style="width: 100%;" />`
    );
  }

  expandItems(data, event) {
    $(".collection_" + data.collection_id + " .collapse").collapse("show");
    $(".expand_" + data.collection_id).addClass("d-none");
    $(".collapse_" + data.collection_id).removeClass("d-none");
  }

  collapseItems(data, event) {
    $(".collection_" + data.collection_id + " .collapse").collapse("hide");
    $(".collapse_" + data.collection_id).addClass("d-none");
    $(".expand_" + data.collection_id).removeClass("d-none");
  }

  processAutoComplete(event) {
    $(".line_item_colour").autocomplete({
      minLength: 2,
      source: function (request, response) {
        $.ajax({
          url:
            `/api/v2/quote_versions/line-item-colours?term=` +
            request.term +
            "&per_page=10",
          method: "GET",
          dataType: "json",
          contentType: "application/json",
          success: function (res) {
            let data = res.data;
            if (data != null && data.length > 0) {
              var numberArray = data.map((d) => d.title);
              response(numberArray);
            }
          },
        });
      },
      open: function (event, ui) {
        $("html, body").css({ overflow: "hidden" });
      },
      close: function () {
        $("html, body").css({ overflow: "inherit" });
      },
      position: { my: "left top", at: "left bottom", collision: "flip" },
    });
  }

  componentDidMount() {
    axios.get(`/api/proposal/${this.state.quoteVersionID}`).then((res) => {
      let tempArr = [];
      res.data.scope_collections.forEach((scopeCollection) =>
        tempArr.push(scopeCollection)
      );

      this.setState((preState) => {
        return {
          scopeCollections: tempArr,
          quoteVersion: { ...res.data },
          isLoading: false,
        };
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    let selection = JSON.parse(localStorage.getItem("draft-" + window.hash_id));
    if (selection && selection == 2) {
      $(".item-select-box").removeClass("d-none");
    }
  }

  render() {
    let width20 = {
      width: "20%",
    };
    let width15 = {
      width: "15%",
    };
    let width10 = {
      width: "10%",
    };
    let width5 = {
      width: "5%",
    };
    let maxWidth70 = {
      maxWidth: "70px",
    };
    let minWidth32 = {
      minWidth: "32px",
    };
    let height200 = {
      height: "200px",
    };

    let displayNone = {
      display: "none",
    };

    const statusName = this.state.quoteVersion.quote_status.name;
    const colourName =
      (statusName == "Pending" || statusName == "Quote Sent" || "Draft Sent") &&
      this.state.quote.job_id == null
        ? "Colour"
        : "";

    const loadingView = this.state.isLoading ? (
      <div className="spinner loading-spinner">
        <div
          className="d-flex align-items-center justify-content-center"
          style={height200}
        >
          <i className="fas fa-spinner fa-spin fa-2x"></i>
        </div>
      </div>
    ) : null;

    return (
      <div className="table-responsive" id="accordion">
        {loadingView}

        {this.state.scopeCollections.map((scopeCollection) => (
          <div key={scopeCollection.id} className="pb-5">
            <div className="position-relative">
              <div class="row w-100">
                <div class="col-md-12 pr-0">
                  <div class="row mt-4">
                    <div class="col-12 col-sm-8">
                      <h4 className="text-red">{scopeCollection.name}</h4>
                    </div>
                    <div class="col-12 col-sm-4 pr-0">
                      <div
                        className={`scope-expand-div scope-section-btn cursor-pointer expand-div expand_${scopeCollection.id}`}
                        onClick={this.expandItems.bind(this, {
                          collection_id: scopeCollection.id,
                        })}
                      >
                        <p className="text-red">
                          <strong>
                            Expand section
                            <i className="ml-2 fa fa-chevron-circle-up"></i>
                          </strong>
                        </p>
                      </div>

                      <div
                        className={`scope-expand-div scope-section-btn cursor-pointer d-none collapse-div collapse_${scopeCollection.id}`}
                        onClick={this.collapseItems.bind(this, {
                          collection_id: scopeCollection.id,
                        })}
                      >
                        <p className="text-red">
                          <strong>
                            Collapse section
                            <i className="ml-2 fa fa-chevron-circle-down"></i>
                          </strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {scopeCollection.line_item_collections.map(
              (lineItemCollection, index) => (
                <div
                  key={lineItemCollection.id}
                  className={`collection_${scopeCollection.id}`}
                >
                  <div className="mb-1 line-item-collection-header-div">
                    <div className="scope-expand-div line-item-collection-header">
                      <p>
                        <a
                          data-toggle="collapse"
                          data-parent="#accordion"
                          className={`panel-heading ${
                            index == 0 ? "item-first-entry" : "collapsed"
                          }`}
                          href={`#lineItemCollection_${lineItemCollection.id}`}
                        >
                          <strong className="text-expand">Expand</strong>
                          <strong className="text-collapse">Collapse</strong>
                          <i className="ml-2 fa fa-chevron-circle-down"></i>
                        </a>
                      </p>
                    </div>
                    <h5 className="mt-4">{lineItemCollection.name}</h5>
                  </div>

                  <div
                    className={`spec-table-wrap w-100 panel-collapse collapse ${
                      index == 0 ? "first-entry-wrap show" : ""
                    }`}
                    id={`lineItemCollection_${lineItemCollection.id}`}
                  >
                    <table className="table desktop-view">
                      <thead>
                        <tr>
                          <th style={width20}>Name</th>
                          <th style={maxWidth70}>Width</th>
                          <th style={maxWidth70}>Height</th>
                          <th style={minWidth32}>QTY</th>
                          <th style={width15}>Unit Of Measure</th>
                          <th style={width10}>Specifications</th>
                          <th style={width5}>
                            <input
                              type="checkbox"
                              className="group-select align-middle d-none ml-2"
                              onClick={this.toggleGroupBoxes.bind(this, {
                                collection_id: lineItemCollection.id,
                              })}
                            />
                          </th>
                          <th style={width20}>{colourName}</th>
                          <th style={width20}>Photos</th>
                        </tr>
                      </thead>

                      <tbody
                        className="line-item-list"
                        data-hash_id={this.state.quoteVersion.hash_id}
                      >
                        {lineItemCollection.line_items.map((lineItem) => (
                          <tr key={lineItem.id}>
                            <td>{lineItem.name}</td>
                            <td>{lineItem.width > 0 ? lineItem.width : ""}</td>
                            <td>
                              {lineItem.height > 0 ? lineItem.height : ""}
                            </td>
                            <td>{lineItem.quantity}</td>
                            <td>{lineItem.unit_of_measure.name}</td>
                            <td>
                              {lineItem.specifications.map((specification) => (
                                <span key={specification.id}>
                                  {specification.code}
                                </span>
                              ))}
                            </td>
                            <td>
                              <input
                                type="checkbox"
                                className={`align-middle ml-2 d-none item-select-box select-box-${lineItem.id} group-${lineItemCollection.id}`}
                                data-collection-id={lineItemCollection.id}
                                data-select_box_id={lineItem.id}
                                onClick={this.toggleBulkButton.bind(this)}
                              />
                            </td>
                            <td>
                              {colourName == "Colour" ? (
                                <div className="ui-widget">
                                  <div className="d-inline-flex">
                                    <input
                                      name="line_item_colour[]"
                                      defaultValue={
                                        lineItem.colour ? lineItem.colour : ""
                                      }
                                      className={`form-control border-radius-8 line_item_colour line_item-${lineItem.id}`}
                                      data-line_item_id={lineItem.id}
                                      onChange={this.processAutoComplete.bind(
                                        this
                                      )}
                                      placeholder="Input colour"
                                    />
                                    <label
                                      id={`match-text-${lineItem.id}`}
                                      className="match-text align-middle ml-2 d-none"
                                    >
                                      Match Existing
                                    </label>
                                    <label
                                      id={`colour-consult-text-${lineItem.id}`}
                                      className="colour-consult-text align-middle mr-2 d-none"
                                    >
                                      Colour Consultant
                                    </label>
                                  </div>
                                </div>
                              ) : (
                                <span>{lineItem.colour}</span>
                              )}
                            </td>
                            <td>
                              <div className="slick-slider-photos d-flex flex-wrap w-100">
                                {lineItem.photos.map((photo) => (
                                  <div key={photo.id}>
                                    {photo.media_type == "image" ? (
                                      <a
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#scopePhoto"
                                        data-caption={lineItem.name}
                                        onClick={this.setPhoto.bind(this, {
                                          photo: photo.url,
                                        })}
                                      >
                                        <img
                                          className="mb-1 mr-1"
                                          height="50"
                                          width="50"
                                          src={photo.url}
                                        />
                                      </a>
                                    ) : (
                                      <div
                                        className="video-lightbox"
                                        data-video={photo.url}
                                        data-caption={lineItem.name}
                                      >
                                        <video height="50" width="50">
                                          <source
                                            src={photo.url}
                                            type="video/mp4"
                                          >
                                            Your browser does not support the
                                            video tag.
                                          </source>
                                        </video>
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    <div className="mobile-view" style={displayNone}>
                      {lineItemCollection.line_items.map((lineItem) => (
                        <div key={lineItem.id} className="mobile-div">
                          <p className="mb-0">{lineItem.name}</p>
                          <p className="font-14 mt-0 blue-span">
                            {lineItem.width > 0 && (
                              <>
                                {lineItem.width}m W
                                {lineItem.height > 0 ? " x " : <br />}
                              </>
                            )}
                            {lineItem.height > 0 && (
                              <>
                                {lineItem.height}m H
                                <br />
                              </>
                            )}
                            Specification:{" "}
                            {lineItem.specifications.map((specification) => (
                              <span key={specification.id}>
                                {specification.code}
                              </span>
                            ))}
                          </p>

                          <p className="font-14">Qty: {lineItem.quantity}</p>

                          <div className="slick-slider-photos d-flex flex-wrap w-100">
                            {lineItem.photos.map((photo) => (
                              <div key={photo.id}>
                                {photo.media_type == "image" ? (
                                  <a
                                    href="#"
                                    data-toggle="modal"
                                    data-target="#scopePhoto"
                                    data-caption={lineItem.name}
                                    onClick={this.setPhoto.bind(this, {
                                      photo: photo.url,
                                    })}
                                  >
                                    <img
                                      className="mb-1 mr-1"
                                      height="32"
                                      width="32"
                                      src={photo.url}
                                    />
                                  </a>
                                ) : (
                                  <div
                                    className="video-lightbox"
                                    data-video={photo.url}
                                    data-caption={lineItem.name}
                                  >
                                    <video height="32" width="32">
                                      <source src={photo.url} type="video/mp4">
                                        Your browser does not support the video
                                        tag.
                                      </source>
                                    </video>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>

                          <div className="d-inline-flex line-options">
                            <input
                              type="checkbox"
                              className={`align-middle mr-2 d-none item-select-box select-box-${lineItem.id} group-${lineItemCollection.id}`}
                              data-collection-id={lineItemCollection.id}
                              data-select_box_id={lineItem.id}
                              onClick={this.toggleBulkButton.bind(this)}
                            />
                            {colourName == "Colour" ? (
                              <div className="ui-widget">
                                <div className="d-inline-flex">
                                  <span className="mt-2 mr-2">Colour:</span>
                                  <input
                                    name="line_item_colour[]"
                                    defaultValue={
                                      lineItem.colour ? lineItem.colour : ""
                                    }
                                    className={`form-control border-radius-8 line_item_colour line_item-${lineItem.id}`}
                                    data-line_item_id={lineItem.id}
                                    onChange={this.processAutoComplete.bind(
                                      this
                                    )}
                                    placeholder="Input colour"
                                  />
                                  <label
                                    id={`match-text-${lineItem.id}`}
                                    className="match-text align-middle ml-2 d-none"
                                  >
                                    Match Existing
                                  </label>
                                  <label
                                    id={`colour-consult-text-${lineItem.id}`}
                                    className="colour-consult-text align-middle mr-2 d-none"
                                  >
                                    Colour Consultant
                                  </label>
                                </div>
                              </div>
                            ) : (
                              <span>{lineItem.colour}</span>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        ))}

        <div
          className="modal fade"
          id="scopePhoto"
          tabindex="-1"
          role="dialog"
          aria-labelledby="scopePhotoTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

if (document.getElementById("scope-of-work-items-app")) {
  const element = document.getElementById("scope-of-work-items-app");
  const props = { ...element.dataset };

  ReactDOM.render(
    <ScopeOfWorkItemsApp {...props} />,
    document.getElementById("scope-of-work-items-app")
  );
}
